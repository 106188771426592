import axios, { AxiosResponse } from 'axios'

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: '',
  timeout: 6000 // 请求超时时间
})

// 异常拦截处理器
const errorHandler = (error) => {
  if (error.response) {
    // const  = error.response
  }
  return Promise.reject(error)
}

// 请求拦截器
const requestHandler = (config) => {
  //   const savedToken = localStorage.get(STORAGE_TOKEN_KEY)
  // 如果 token 存在
  // 让每个请求携带自定义 token, 请根据实际情况修改
  //   if (savedToken) {
  //     config.headers[REQUEST_TOKEN_KEY] = savedToken
  //   }
  return config
}

// Add a request interceptor
request.interceptors.request.use(requestHandler, errorHandler)

// 响应拦截器
const responseHandler = (response) => {
  //   return response.data;
  const res = response.data
  // if the custom code is not 20000, it is judged as an error.
  return res
}

// Add a response interceptor
request.interceptors.response.use(responseHandler, errorHandler)

export { AxiosResponse }

export default request
