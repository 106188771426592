import request from './request'
import crypto from './crypto'

export default {
  mixin: [crypto],
  data() {
    return {
      apiConfig: {
        BaseUrl: undefined,
        ApiSercet: undefined,
        CurrentExten: undefined
      }
    }
  },
  methods: {
    // 检查接口配置
    checkCOnfig() {
      if (this.apiConfig.BaseUrl !== undefined && this.apiConfig.ApiSercet !== undefined) {
        this.apiConfig.token = this.JWTToken(this.apiConfig.ApiSercet)
        return true
      }
      console.log('检查接口配置')
      return false
    },
    // 响应处理
    requestResponse(url, params) {
      return new Promise((resolve, reject) => {
        request.post(url, { ...params })
          .then(Response => {
            resolve(Response)
          })
          .catch(reject)
      })
    },
    // 呼叫示忙/示闲数据
    apiGetDndList() {
      if (this.checkCOnfig()) {
        const params = Object.assign({ jwttoken: this.apiConfig.token }, {})
        return this.requestResponse(`${this.apiConfig.BaseUrl}/v2/getextensionbusylist`, params)
      }
    },
    // 获取目的地/转接使用
    apiGetDestination() {
      if (this.checkCOnfig()) {
        const params = Object.assign({ jwttoken: this.apiConfig.token }, {})
        return this.requestResponse(`${this.apiConfig.BaseUrl}/v2/getDestination`, params)
      }
    },
    // 呼叫
    apiDial(params) {
      if (this.checkCOnfig()) {
        params = Object.assign({ jwttoken: this.apiConfig.token }, params)
        return this.requestResponse(`${this.apiConfig.BaseUrl}/v2/dial`, params)
      }
    },
    // 示忙/示闲
    apiSetDnd(params) {
      if (this.checkCOnfig()) {
        params = Object.assign({ jwttoken: this.apiConfig.token }, params)
        return this.requestResponse(`${this.apiConfig.BaseUrl}/v2/setdnd`, params)
      }
    },
    // 示忙/示闲
    apiGetDnd(params) {
      if (this.checkCOnfig()) {
        params = Object.assign({ jwttoken: this.apiConfig.token }, params)
        return this.requestResponse(`${this.apiConfig.BaseUrl}/v2/getdnd`, params)
      }
    },
    // 转接
    apiTransfer(params) {
      if (this.checkCOnfig()) {
        params = Object.assign({ jwttoken: this.apiConfig.token }, params)
        return this.requestResponse(`${this.apiConfig.BaseUrl}/v2/transfer`, params)
      }
    },
    // 三方通话
    apiConference(params) {
      if (this.checkCOnfig()) {
        params = Object.assign({ jwttoken: this.apiConfig.token }, params)
        return this.requestResponse(`${this.apiConfig.BaseUrl}/v2/conference`, params)
      }
    },
    // 提交备注
    apiSubmitRemarks(params) {
      return this.requestResponse(`https://test03.zadacash.com/mikangyun/task/setRemarks`, params)
    },
    // 提交备注
    apiSendMessage(params) {
      return this.requestResponse(`https://test03.zadacash.com/mikangyun/task/sendMessage`, params)
    },
    // 获取客户逾期信息
    apiCollectionInfo(params) {
      return this.requestResponse(`https://test03.zadacash.com/mikangyun/collection/info`, params)
    },
    // 检查状态
    apiCheckAccountStatus(params) {
      return this.requestResponse(`https://test03.zadacash.com/mikangyun/task/getextensionstatus`, params)
    },
    // 检查状态
    apiGetPhoneType(params) {
      return this.requestResponse(`https://test03.zadacash.com/mikangyun/getPhoneType`, params)
    },
    // 登录
    apiLogin(params) {
      return this.requestResponse(`https://test03.zadacash.com/mikangyun/login`, params)
    },
    // 验证token
    apiValidateToken(params) {
      return this.requestResponse(`https://test03.zadacash.com/mikangyun/validateToken`, params)
    },
    // 验证token
    apiOverdueConnection(params) {
      return this.requestResponse(`https://test03.zadacash.com/mikangyun/collection/overdueConnection`, params)
    },
    // 调整周期
    apiUpdateCycle(params) {
      return this.requestResponse(`https://test03.zadacash.com/mikangyun/updateCycle`, params)
    }
  }
}
