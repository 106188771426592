import JWT from 'jsonwebtoken'

export default {
  methods: {
    JWTToken(secret, payload = {}) {
      var time = Date.parse(new Date()) / 1000
      payload = Object.assign({
        iat: time,
        exp: time + 60 * 60 * 12
      }, payload)
      return JWT.sign(payload, secret)
    }
  }
}
